const DOMAIN_MAP = {
  parisladefense: "parisladefense3d",
  carte3d: "mulhouse",
  heritagecd93: "heritagecd93-mappr",
  medicen: "medicen-mappr",
  sceaux: "mappr-sceaux",
  etixia: "mappr-etixia",
  "epinay-sur-seine": "mappr-epinay",
  cmf: "cmf-mappr",
  euromed: "euromed-mappr",
  altarea: "altarea-mappr",
  linkcity: "linkcity-mappr",
  nantes: "nantes-mappr",
  valleesud: "valleesud-mappr",
  issylesmoulineaux: "issylesmoulineaux",
  garges: "garges-mappr",
  clichy: "clichy-mappr",
  lacourneuve: "mappr-lacourneuve",
  espas: "epsas",
  projets3d: "creil",
  parera: "mappr-parrera",
  paris: "paris-mappr",
  orly: "vstory-orly",
};

export const getDomain = () => {
  let d;
  if (process.env.NODE_ENV === "production") {
    // if (!(d = process.env.REACT_APP_PROJECT_DOMAIN)) {
    const hostname = window.location.host;

    if (window.location.hostname === "localhost") {
      const params = new URLSearchParams(window.location.search);
      d = params.get("project");
    } else {
      d = hostname.substring(0, hostname.indexOf("."));
    }
    if (DOMAIN_MAP[d.toLowerCase()]) {
      d = DOMAIN_MAP[d];
    }
    // }
  } else {
    d = process.env.REACT_APP_PROJECT_DOMAIN || "vectuel-demo";
    if (DOMAIN_MAP[d.toLowerCase()]) {
      d = DOMAIN_MAP[d];
    }
  }
  return d;
};

export const getApiUrl = (type = "graphql") => {
  if (type === "graphql") {
    return isColas()
      ? `https://colas-webapi.mappr.online/${type}`
      : process.env.REACT_APP_MPR_GQL_URL;
  } else if (type === "api") {
    return isColas()
      ? `https://colas-webapi.mappr.online/${type}`
      : process.env.REACT_APP_MPR_API_URL;
  } else if (type === "tile") {
    return isColas()
      ? "https://colas-webapi.mappr.online/api"
      : process.env.REACT_APP_MPR_API_URL;
  }
};

const isColas = () => {
  const domain = getDomain();
  return ["colas-recette", "mappr-colas-dev", "colas-prod"].includes(domain);
};
