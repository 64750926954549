import React, {useCallback, useContext, useState} from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import ReactHtmlParser from "react-html-parser";
import {AppContext} from "../../../context";
import ExpandCollapse from "../../ExpandCollapse";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import POIFields from "../POIFields/POIFields";
import POIField from "../POIFields/POIField/POIField";
import POIPrintPreview from "../POIPrintPreview/POIPrintPreview";
import {useTranslation} from "react-i18next";
import "./POIDetails.scss";
import {PoiIsochroneContent} from "./PoiIsochroneContent";
import moment from "moment";

const ICON_TYPES = [
    "dwg",
    "e57",
    "ifc",
    "las",
    "laz",
    "msa",
    "others",
    "pdf",
    "rvt",
    "xlsx",
];

function isValidDate(dateString) {
    const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})Z$/;
    return regex.test(dateString);
}

function POIContent({poi, isFullscreen, projectConfigs, printPreviewRef}) {
    const {t} = useTranslation();
    const [currentModelId, setCurrentModelId] = useState(
        poi.properties.models && poi.properties.models.length
            ? poi.properties.models[0]
            : null
    );
    const {mprApiUrl} = useContext(AppContext);

    const placeProps = poi.properties;

    const threeDs = placeProps.models;

    const renderContentItem = useCallback(
        (item, key) => {
            let parentClass = "",
                content = null;
            switch (item.type) {
                case "fields": {
                    parentClass = "card-text mb-3";
                    content = (
                        // <></>
                        <POIFields
                            item={poi.properties}
                            poiFields={projectConfigs.poiPageFields}
                            index={item.fieldsBlockIndex}
                            projectConfigs={projectConfigs}
                            renderItem={(item) => (
                                <POIField
                                    {...item}
                                    inline={projectConfigs.poiFieldsView === "line"}
                                />
                            )}
                        />
                    );
                    break;
                }
                case "text": {
                    parentClass = "card-text";

                    let textContent = placeProps[item.field] || "";
                    if (item.isMarkdown) {
                        const source = textContent.replace(/(?:\r\n|\r|\n)/g, "\n\n");
                        textContent = <ReactMarkdown>{source || ""}</ReactMarkdown>;
                    } else {
                        const source = textContent.replace(/(?:\r\n|\r|\n)/g, "\n\n");
                        textContent = ReactHtmlParser(source);
                    }
                    //   const textContentextContentt = (
                    //     <LocalizedField
                    //       item={placeProps}
                    //       field={item.field}
                    //       lang={lang}
                    //       type={item.format}
                    //     >
                    //       {({ value }) =>
                    //         value ? (
                    //           <>
                    //             {item.title && ReactHtmlParser(item.title)}
                    //             <div className={item.className}>{value}</div>
                    //           </>
                    //         ) : null
                    //       }
                    //     </LocalizedField>
                    //   );

                    if (item.wrapperTag) {
                        textContent = React.createElement(item.wrapperTag, null, textContent);
                    }

                    if (item.expandable) {
                        content = (
                            <ExpandCollapse
                                expandOnBodyClick={true}
                                previewHeight="140"
                                expandText={
                                    <button
                                        className="btn btn-link btn-block border-top-0 text-right p-0 text-smaller p-0">
                                        <i className="icon icon-arrow_down mr-2"/> Voir plus
                                    </button>
                                }
                                collapseText={
                                    <button className="btn btn-link btn-block border-top-0 text-right p-0 text-smaller">
                                        <i className="icon icon-arrow_up mr-2"/> Voir moins
                                    </button>
                                }
                                ellipsis={false}
                            >
                                {textContent}
                            </ExpandCollapse>
                        );
                    } else {
                        content = textContent;
                    }
                    break;
                }
                case "table": {
                    parentClass = "card-text";
                    let tableContents = placeProps[item.field] || [];
                    const newOrder = tableContents.map((a) => {
                        let row = {};
                        item.tableColumns.forEach((c) => (row[c] = a[c]));
                        return row;
                    });

                    if (tableContents.length > 0) {
                        content = (
                            <Table responsive className="mb-3 mt-3">
                                <tbody>
                                <tr
                                    key={"header"}
                                    className="text-smaller text-light bg-dark"
                                >
                                    {Object.keys(newOrder[0]).map((key) => (
                                        <th className="p-2">
                                            {t(`project:table.column.${key}`)}
                                        </th>
                                    ))}
                                </tr>
                                {newOrder.map((item, index) => (
                                    <tr key={item.id || index} className="text-smaller">
                                        {Object.values(item).map((val) => {
                                                if (isValidDate(val)) {
                                                    return <td className="p-2">{moment(val).format('YYYY-MM-DD')}</td>
                                                } else {
                                                    return <td className="p-2">{val}</td>
                                                }
                                            }
                                        )}
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        );
                    }
                    break;
                }
                case "filesList": {
                    parentClass = "card-text";
                    let tableContents = placeProps[item.field] || [];
                    const newOrder = tableContents.map((a) => {
                        let row = {};
                        item.tableColumns.forEach((c) => (row[c] = a[c]));
                        return row;
                    });

                    if (tableContents.length > 0) {
                        content = (
                            <Table responsive className="mb-3 mt-3 files-list">
                                <tbody>
                                <tr
                                    key={"file-table-header"}
                                    className="text-smaller text-light bg-dark"
                                >
                                    {Object.keys(newOrder[0]).map((key) => (
                                        <th className="p-2" key={key}>
                                            {t(`project:table.column.${key}`)}
                                        </th>
                                    ))}
                                </tr>
                                {newOrder.map((item, index) => (
                                    <tr key={item.id || index} className="text-smaller ">
                                        {Object.keys(newOrder[0]).map((key) => (
                                            <td className="p-2" key={key}>
                                                {key === "fileName" ?
                                                    tableContents[index]["fileUrl"] ? (
                                                        <a
                                                            rel="noreferrer"
                                                            href={tableContents[index]["fileUrl"]}
                                                            target={"_blank"}
                                                        >
                                                            {item[key]}
                                                        </a>
                                                    ) : ReactHtmlParser(item[key])
                                                    : key === "fileExtension" ? (
                                                        ICON_TYPES.includes(
                                                            item[key].replace(/^\./, "")
                                                        ) ? (
                                                            <img
                                                                src={`/img/files/${item[key].replace(
                                                                    /^\./,
                                                                    ""
                                                                )}.png`}
                                                                alt=""
                                                                height="28"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/img/files/others.png"
                                                                alt=""
                                                                height="28"
                                                            />
                                                        )
                                                    ) : (
                                                        item[key]
                                                    )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        );
                    }
                    break;
                }
                default: {
                    content = item.field;
                }
            }
            return (
                <div key={key}
                     className={[parentClass, item.classList].filter(_ => _ !== null && _ !== undefined && _ !== '').join(" ")}>
                    {item.title && ReactHtmlParser(t(`project:${item.title}`))}
                    {content}
                </div>
            );
        },
        [poi, placeProps]
    );

    const getImageURL = (domain) => {
        let image =
            placeProps.mediasets &&
            placeProps.mediasets.main &&
            placeProps.mediasets.main.filePath
                ? placeProps.mediasets.main.filePath.split("/" + domain + "/").join("")
                : null;

        if (!image) {
            image =
                projectConfigs.appLogos[0] &&
                projectConfigs.appLogos[0].image.filePath
                    .split("/" + domain + "/")
                    .join("");
        }

        return `${
            mprApiUrl
        }/projects/${domain}/image/800x600_${
            projectConfigs.poiMainImageFit === "contain"
                ? "inside"
                : projectConfigs.poiMainImageFit
        }/${image}`;
    };
    return (
        <AppContext.Consumer>
            {({projectConfigs, domain}) => {
                const iconValue = poi.properties[projectConfigs.iconFieldName];
                const iconMap = iconValue
                    ? projectConfigs.iconMapping && projectConfigs.iconMapping.find((_) => _.value === iconValue)
                    : null;
                let iconType = iconMap
                    ? iconMap.iconName
                    : "material_ic_location_on_48px";
                if (iconType && iconType.match(/([0-9a-fA-F]{6})$/)) {
                    iconType = iconType.substr(0, iconType.lastIndexOf("_"));
                }
                const subTitle = projectConfigs.poiSubtitleFields
                    .map((field) => {
                        return placeProps[field.fieldName];
                    })
                    .join(",");
                const image = (
                    <img
                        src={getImageURL(domain)}
                        className={
                            "rounded align-top img-" + projectConfigs.poiMainImageFit
                        }
                        alt="..."
                    />
                );

                const poiPageContent = projectConfigs.poiPageContents.filter((item) => item.type !== 'table' || (item.type === 'table' && placeProps[item.field] && placeProps[item.field].length > 0));

                const fields = poiPageContent.map((item, index) => renderContentItem(item, index));

                const threeDLinks =
                    threeDs && threeDs.length > 1 ? (
                        <>
                            <strong>3D</strong>
                            {threeDs &&
                                threeDs.length &&
                                threeDs.map((threeD, index) => (
                                    <Button
                                        disabled={currentModelId === threeD}
                                        className="ml-2"
                                        size="sm"
                                        key={threeD}
                                        onClick={() => {
                                            const event = new CustomEvent("mpr-switch-poi-3d", {
                                                detail: {
                                                    currentModelId: currentModelId,
                                                    nextModelId: threeD,
                                                    nextModelGroupIndex: index,
                                                },
                                            });
                                            document.dispatchEvent(event);
                                            setCurrentModelId(threeD);
                                        }}
                                    >
                                        {index + 1}
                                    </Button>
                                ))}
                        </>
                    ) : null;

                return (
                    <>
                        <div
                            className="card border-0 rounded-0 bg-white text-dark cursor-default position-relative poi-heading">
                            <div className="card-image-container">
                                {image}
                                <div className="bg-primary-transparent"/>
                                <div
                                    className={`card-img-overlay d-flex align-items-end p-3 ${
                                        isFullscreen ? "col col-md-8 m-auto" : ""
                                    }`}
                                >
                                    <div className="card-title text-white row w-100 m-0">
                                        <div className="bg-primary rounded icon-container text-center">
                                            <i className={`ic icon text-white ic-${iconType}`}/>
                                        </div>
                                        <div className="col">
                                            <OverlayTrigger
                                                placement="bottom"
                                                delay={{show: 250, hide: 160}}
                                                overlay={
                                                    <Tooltip id={`tooltip-poi-title`}>
                                                        {placeProps[projectConfigs.poiTitleField]}
                                                    </Tooltip>
                                                }
                                            >
                                                <h4>{placeProps[projectConfigs.poiTitleField]}</h4>
                                            </OverlayTrigger>
                                            <p className="card-text mb-0">{subTitle}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`card-body text-dark ${
                                isFullscreen ? "col col-md-8 m-auto" : ""
                            }`}
                        >
                            {fields}
                            {projectConfigs.showPoiURLButton && poi.properties[projectConfigs.poiUrlField] &&
                                <a href={poi.properties[projectConfigs.poiUrlField]} target="_blank" rel="noreferrer">
                                    <Button>{t(`project:${projectConfigs.poiUrlField}`)}</Button>
                                </a>
                            }

                        </div>
                        <div className="card-body">{threeDLinks}</div>
                        <POIPrintPreview
                            ref={printPreviewRef}
                            title={placeProps[projectConfigs.poiTitleField]}
                            subTitle={subTitle}
                            iconType={iconType}
                            image={image}
                            fields={fields}
                            // details={threeDLinks}
                        />
                        {projectConfigs.showTargomoSwitch && placeProps[projectConfigs.poiIsochroneField] &&
                            <PoiIsochroneContent/>
                        }
                    </>
                );
            }}
        </AppContext.Consumer>
    );
}

POIContent.propTypes = {
    poi: PropTypes.object,
    isFullscreen: PropTypes.bool,
    printPreviewRef: PropTypes.object,
};
POIContent.defaultProps = {
    isFullscreen: false,
};

export default POIContent;
